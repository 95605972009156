import type { RouteLocationNormalized } from '#vue-router'

export default async ({ name: routeName }: RouteLocationNormalized) => {
  const supabaseUser = useSupabaseUser()
  if (!supabaseUser.value) return

  if (routeName === 'organizationId-myself-setup') {
    const { getUserAccount } = useUserAccount()
    const { data: account } = await useAsyncData(async () => {
      return await getUserAccount(supabaseUser.value!.id)
    })
    // 初期設定済の場合はトップ画面へ遷移
    if (!account.value?.is_pending) {
      return await navigateTo('/') // TODO: マイページへ遷移させる
    }
  }
}
